/* ----------------------------------
	Contact Us Section Styles
 ------------------------------------ */
.contact-form {
    padding-top: 40px;

    @media (max-width: 585px) {
        padding-top: 20px;
    }

    .single-personal-info {
        margin-bottom: 20px;
    }

    input,
    textarea {
        width: 100%;
        background: #f8f8f8;
        line-height: 1;
        padding: 25px 30px;
        border: 0px;

        @media (max-width: 585px) {
            padding: 15px;
            font-size: 13px;
        }
    }
    textarea {
        height: 220px;

        @media (max-width: 767px) {
            height: 160px;
        }
    }
    label {
        color: #202426;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 10px;
        text-transform: capitalize;

        span {
            color: #727475;
            margin-left: 5px;
        }
    }

    input[type="submit"],
    .submit-btn {
        background: $theme-color;
        color: #fff;
        font-weight: 700;
        line-height: 1;
        padding: 25px 40px;
        transition: all 0.3s ease-in-out;
        width: 280px;

        &.active,
        &:hover {
            background-color: $second-color;
        }

        i {
            margin-right: 8px;
        }

        @media (max-width: 400px) {
            width: 100%;
        }
    }
}

.single-contact-card {
    background-color: #f8f8f8;
    padding: 40px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out;

    @media (max-width: 585px) {
        padding: 30px;
    }

    &:hover {
        background-color: #202426;

        .icon {
            color: #fff !important;
            background-color: $theme-color !important;
        }

        .bottom-part {
            .icon {
                color: $theme-color !important;
                background-color: #fff !important;
            }
        }

        p,
        span {
            color: #c5c7c8;
        }
        h4 {
            color: #fff;
        }
    }

    .top-part {
        overflow: hidden;
        .icon {
            width: 70px;
            height: 70px;
            font-size: 20px;
            line-height: 70px;
            border-radius: 50%;
            background-color: #fff;
            text-align: center;
            float: left;
            color: $theme-color;
            overflow: hidden;
            margin-right: 20px;
        }
        .title {
            overflow: hidden;
        }
    }
    .bottom-part {
        margin-top: 25px;
        overflow: hidden;

        .icon {
            width: 50px;
            height: 50px;
            font-size: 14px;
            line-height: 50px;
            border-radius: 50%;
            background-color: #fff;
            text-align: center;
            float: right;
            overflow: hidden;
            margin-left: 20px;
        }
        .info {
            display: inline-block;
            overflow: hidden;
        }
    }
}

.contact-map-wrap {
    iframe {
        height: 450px;

        @media (max-width: 991px) {
            height: 300px;
        }
    }
}

.make-appointment-wrapper {
    background-color: $second-color;
}
