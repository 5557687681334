/*********************************************************************************

	Template Name: Trydo React Degital Agency Template
	Note: This is style css.

**********************************************************************************/

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|	|___ mainmenu
	|
	|
	|___Inner Styles
	|	|___ breadcrump 
	|	|___ section-title 
	|	|___ button
	|	|___ pagination
	|	|___ socialicon
	|	|___ footer
	|
	|___Elements Styles
	|	|___ portfolio 
	|	|___ service 
	|	|___ slider 
	|	|___ counterup 
	|	|___ testimonial 
	|	|___ brand 
	|	|___ team 
	|	|___ callto-action 
	|	|___ tab 
	|	|___ accordion 
	|	|___ list 
	|	|___ contact 
	|
	|___BLog Styles
	|	|___ blog 
	|	|___ blog-details 
	|
	|___Template Styles
	|	|___ about 
	|	|___ portfolio-details
	|	|___ service-details
	|	|___ preview
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/

/**************************************
    Default Styles
***************************************/
@import "default/variables";
@import "default/reset";
@import "default/typography";
@import "default/helper";
@import "default/mixins";
@import "default/animations";
@import "default/buttons";

/**************************************
    Inner Styles
***************************************/
@import "common/common";

/**************************************
    Elements Styles
***************************************/
@import "elements/header";
@import "elements/mobileMenu";
@import "elements/hero";
@import "elements/about";
@import "elements/blog";
@import "elements/contact";
@import "elements/contentBlock";
@import "elements/cta";
@import "elements/faq";
@import "elements/features";
@import "elements/footer";
@import "elements/funFact";
@import "elements/price";
@import "elements/projects";
@import "elements/section";
@import "elements/services";
@import "elements/team";
@import "elements/testimonial";


.gallery-content{
	columns: 5 320px;
  }
  
  .gallery-item{
	columns: 5 320px;
  }
  
  .gallery-content img{
	margin: 10px;
  }

  .img-modal{
	width: 100%;
	height: 100%;
	object-fit: cover;
  }

  .formContent__checkOpt {
	margin-right: 10px;
	margin-left: 10px;
	display: flex;
	align-self: center;
	align-items: center;
  }
  
  .form-check-label {
	margin-left: 20px !important;
  }

  .formContent {
	width: 95% !important;
  }
  
  .content-directorios {
	align-self: center;
  }

  .gxWltO{
	bottom: 110px !important;
  }

  .content-menu-movil{
	padding: 15px !important;
  }

  .item-menu-movil{
	padding: 16px 10px;
	color: white !important;
  }

  .item-menu-movil a{
	padding: 10px;
	color: white !important;
	border-bottom: 2px solid rgba(128, 128, 128, 0.355);
  }
  
  .sub-item-menu{
	margin-top: 15px;
	padding-left: 15px;
  }